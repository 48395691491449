import React from "react";

function UpdatesPage() {
  return (
    <div className="privacy-policy">
      <h1 className="privacy-title">Website Updates</h1>
      <p className="privacy-date">Last Updated: April 2, 2025</p>

      <section>
        <h2>Recent Updates</h2>
        <div className="update-item">
          <h3>4/2/25</h3>
          <ul>
            <li>Teacher can now see student auctions for 7 days after they complete, up from 2 days.</li>
            <li>Auctions are no longer updated by polling. They are now updated with Server Sent Events. This should mean faster page updates with regards to auctions changing.</li>
          </ul>
        </div>
        <div className="update-item">
          <h3>1/6/25</h3>
          <ul>
            <li>Rank and Networth will now only be calculated once per day. Previously, it was done every time any user landed on the users page. This was not a problem when there were only a few students but now it is slowing the platform.</li>
            <li>Certificate of Deposits lengths and rates were unbalancing the game. As such, they have been adjusted to 7, 14, 30, 60, 90 days and 1%, 2%, 3%, 4%, 5% Daily Percentage Yield respectivly. Previously it was 14, 30, 50, 70, 90 with rates of 3%, 4%, 5%, 6% and 7%. This should give the teacher better control of the currencies.</li>
          </ul>
        </div>
        {/* <div className="update-item">
          <h3>9/6/24</h3>
          <ul>
            <li>Older updates added to the website</li>
          </ul>
        </div> */}
      </section>

      <section>
        <h2>How Updates Are Applied</h2>
        <p>
          These updates reflect our ongoing commitment to improve user experience and align with feedback.
        </p>
      </section>

      <section>
        <h2>Stay Informed</h2>
        <p>
          Bookmark this page to stay informed about the latest improvements to our platform. If you have questions or suggestions, contact us at:
          <a href="mailto:SchoolBucks@proton.me"> SchoolBucks@proton.me</a>
        </p>
      </section>
    </div>
  );
}

export default UpdatesPage;
