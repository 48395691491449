import React, { useEffect, useMemo } from "react";
import { useDispatch, connect } from "react-redux";
import {  } from "../../../_actions/user_actions";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getClasses, makeAuction, getAuctions, removeAuction } from "../../../_actions/user_actions";
import { Form, Input, Button, Row, DatePicker, Checkbox, Popconfirm, message, Popover, Statistic } from 'antd';
import { DislikeOutlined, LikeOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import Table from '../Table/Table'
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Countdown } = Statistic;

const formItemLayout = {
  labelCol: {
    xs: { span: 44 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

function AuctionPageT(props) {
  const dispatch = useDispatch();
  const userData = useMemo(() => {
    return props.data.user?.userData ?? ["Loading Data"];
  }, [props.data.user?.userData]);
  const classesData = props.data.user?.classes ?? []
  const auctions = props.data.user?.auctions ?? []

  let classValues = []
  const classOptions = classesData.map((klass, index) => {
    classValues.push(klass._id) //error could be here
    return { label: klass.name, value: klass._id }
  })

  const gradeOptions = [
    { label: 'Freshman', value: 'Freshman'},
    { label: 'Sophomores', value: 'Sophomores'},
    { label: 'Juniors', value: 'Juniors'},
    { label: 'Seniors', value: 'Seniors'},
  ]

  const [checkedClassList, setCheckedClassList] = React.useState([]);
  const [checkedYearList, setCheckedYearList] = React.useState([]);
  const [checkedAll, setCheckedAll] = React.useState(true);
  const [checkedClasses, setCheckedClasses] = React.useState(false);
  const [dateRange, setDateRange] = React.useState([moment(), moment().add(15, 'minutes')]);
  const [trueAuction, setTrueAuction] = React.useState(false)

  const somethingChecked = (rowChecked) => {
    if (rowChecked === true) return true
    if (rowChecked === 0) {
      if (checkedClassList.length === 0 && checkedYearList.length === 0 && !checkedClasses) {
        return false
      }
      return true
    }
    if (rowChecked === 1) {
      if (checkedClassList.length === 0 && !checkedAll && !checkedClasses) {
        return false
      }
      return true
    }
    if (rowChecked === 2) {
      if (checkedClassList.length === 0 && checkedYearList.length === 0 && !checkedAll) {
        return false
      }
      return true
    }
    if (rowChecked === 3) {
      if (checkedYearList.length === 0 && !checkedAll && !checkedClasses) {
        return false
      }
      return true
    } 
  }

  const onAllChange = e => {
    const rowChecked = e.target.checked ? true : 0
    if (somethingChecked(rowChecked)) {
      setCheckedAll(e.target.checked)
      setCheckedClassList([])
      setCheckedYearList([])
      setCheckedClasses(false)
    }
  }

  const onTrueAuctionChange = e => {
    setTrueAuction(!trueAuction)
  }

  const onClassesChange = e => {
    const rowChecked = e.target.checked ? true : 2
    if (somethingChecked(rowChecked)) {
      setCheckedClasses(e.target.checked)
      setCheckedAll(false)
      setCheckedClassList([])
      setCheckedYearList([])
    }
  }

  const onYearChange = list => {
    const rowChecked = list.length === 0 ? 1 : true
    if (somethingChecked(rowChecked)) {
      if (list.length < 4) {
        setCheckedYearList(list)
        setCheckedAll(false)
        setCheckedClasses(false)
        setCheckedClassList([])
      }
      else {
        setCheckedAll(true)
        setCheckedYearList([])
        setCheckedClasses(false)
        setCheckedClassList([])
      }
      }
  }

  const onClassChange = list => {
    const rowChecked = list.length === 0 ? 3 : true
    if (somethingChecked(rowChecked)) {  
      if (list.length < classOptions.length) {
        setCheckedClassList(list)
        setCheckedAll(false)
        setCheckedClasses(false)
        setCheckedYearList([])
      }
      else {
        setCheckedAll(false)
        setCheckedYearList([])
        setCheckedClasses(true)
        setCheckedClassList([])
      }
    }
  }

  useEffect(() => {
    dispatch(getClasses())
    dispatch(getAuctions())
    
    let intervalId = setInterval(() => {
      dispatch(getAuctions())
    }, 5 * 60 * 1000);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 15 * 60 * 1000);

    // Cleanup interval and timeout on component unmount
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [dispatch])

  const onDateChange = dates => {
    setDateRange(dates)
  }

  const RemoveComponent = (localProps) => {
    const { row } = localProps
    
    const removeClickHandler = (e, row) => {
      row.type = buttonTitle()
      dispatch(removeAuction(row)).then((response) => {
        console.log(response)
        dispatch(getAuctions())
      })      
      e.stopPropagation()
    }

    const buttonTitle = () => {
      if (new Date(row.endDate) >= new Date()) {
        return "Remove"
      }
      else return "Resolve" 
    }

    const buttonType = () => {
      if (new Date(row.endDate) >= new Date()) {
        return "danger"
      }
      else return "primary" 
    }

    return (
      <Popconfirm onCancel={e => e.stopPropagation()} onConfirm={(e) => removeClickHandler(e, row)} title="Are you sure you want to permanently deactivate this auction?" okText="Yes" cancelText="No">
        <Button onClick={(e) => e.stopPropagation()} type={buttonType()}>{buttonTitle()}</Button>             
      </Popconfirm>
    )
  }

  const updatedAuctionData = auctions.map((row, index) => {
    let temp = {...row}
    temp.students = temp.visibility.map((item, index) => {
      return item
    })
    temp.endDate = moment(temp.endDate).format('YYYY-MM-DD HH:mm:ss')
    temp.countdown = <Countdown value={moment(temp.endDate).valueOf()} format="DD:HH:mm:ss" />
    temp.winner = row.winner_id && row.winner_id.lastName != 'nil' ? <a href={"/student/users/" + row.winner_id._id}>{row.winner_id.firstName + ' ' + row.winner_id.lastName}</a> : ''
    temp.students = temp.students.toString()
    temp._id = row._id
    if (row.approved)temp.approved = <LikeOutlined />
    else temp.approved = <DislikeOutlined />
    if (row.active) temp.remove = <RemoveComponent id={index} row={row} />
    return temp
  })

  const [activeAuction, completedAuction] = updatedAuctionData.reduce(([p, f], e) => (e.active ? [[...p, e], f] : [p, [...f, e]]), [[], []]);

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().subtract(10, 'minutes');
  }

  const activeTableTransactions = {
    onClick: (user) => {
      // props.history.push("/student/users/" + user._id);
    },
    tableHeadings: [
      'bid', 'description', 'students','endDate', 'countdown', 'winner', 'approved', 'remove'
    ],
  }

  const completedTableTransactions = {
    onClick: (user) => {
      // props.history.push("/student/users/" + user._id);
    },
    tableHeadings: [
      'bid', 'description', 'students', 'endDate', 'winner'
    ],
  }
  
  return (
    <div className="body">
      <div className="innerBody">
        <Formik
          enableReinitialize
          initialValues={{
            starting: '0',
            description: ''
          }}
          validationSchema={Yup.object().shape({
            description: Yup.string()
              .required('description is required'),
            starting: Yup.number().min(0)
              .required('Starting price is required'),
          })}

          onSubmit={(values, { setSubmitting }) => {
            let dataToSubmit = {
              description: values.description,
              bid: parseInt(values.starting),
              owner_id: userData._id,
              visibility: [],
              startDate: dateRange[0],
              endDate: dateRange[1],
              maxBid: parseInt(values.starting),
              trueAuction: trueAuction
            }

            if (checkedAll) dataToSubmit.visibility.push("Entire School")
            else if (checkedClasses) dataToSubmit.visibility.push(...classValues)
            else if (checkedYearList.length > 0) dataToSubmit.visibility = checkedYearList
            else dataToSubmit.visibility = checkedClassList
            dispatch(makeAuction(dataToSubmit)).then((response) => {
              console.log(response)
              if (response.payload !== null) {
                message.error(response.payload, 10)
              }
              else {
                message.success("Auction Created")
              }
              dispatch(getAuctions())
              setSubmitting(false);
            })
          }}
        >

          {props => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit
            } = props;

            return (
              <div>
                <div className="form">
                  <h1>New Auction</h1>
                  <Form {...formItemLayout} onSubmit={handleSubmit}>
                    
                    <Form.Item label="Starting Price">
                      <Input
                        id="starting"
                        type="int"
                        value={values.starting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.starting && touched.starting ? 'text-input error' : 'text-input'
                        }
                      />
                      {errors.starting && touched.starting && (
                        <div className="input-feedback">{errors.starting}</div>
                      )}
                    </Form.Item>

                    <Form.Item label="Description">
                      <Input
                        id="description"
                        type="int"
                        value={values.discription}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.description && touched.description ? 'text-input error' : 'text-input'
                        }
                       />
                       {errors.description && touched.description && (
                        <div className="input-feedback">{errors.description}</div>
                      )}
                    </Form.Item>
                  
                    <Form.Item label="Dates">
                      <RangePicker
                        ranges={{
                          '30 Min': [moment(), moment().add(30, 'minutes')],
                          '45 min': [moment(), moment().add(45, 'minutes')],
                          '1 Hour': [moment(), moment().add(1, 'hour')],
                          '1 Day': [moment(), moment().add(1, 'day')]
                        }}
                        disabledDate={disabledDate}
                        value= {dateRange}
                        showTime={true}
                        format="YYYY-MM-DD HH:mm"
                        onChange={onDateChange}
                        allowClear={false}
                      />
                      <Checkbox
                        onChange={onTrueAuctionChange}  
                        checked={trueAuction}
                      >
                      <h3>True Auction</h3>
                      </Checkbox>
                      <Popover 
                        content="If an auction is outbid within the last minute then 2 minutes will be added to the auction"
                      >
                        <QuestionCircleTwoTone />
                      </Popover>
                    </Form.Item>
                    <Form.Item label="Visible To">
                      <Checkbox
                        onChange={onAllChange}  
                        checked={checkedAll}
                        value={-1}
                      >
                        <h3>Entire School</h3>
                      </Checkbox>
                      <br />
                      <Checkbox.Group
                        value={checkedYearList}
                        options={gradeOptions}
                        onChange={onYearChange}
                      />
                      <br />
                      <div hidden={userData.role === 2}>
                        <Checkbox
                          hidden={userData.role === 2}
                          onChange={onClassesChange}
                          checked={checkedClasses}
                          value={0}
                        >
                          <h3>My Classes</h3>
                        </Checkbox>
                      </div>
                      <Checkbox.Group
                        hidden={userData.role === 2}
                        value={checkedClassList}
                        options={classOptions} 
                        onChange={onClassChange}
                      />
                    </Form.Item>

                    <Row type="flex" justify="center" align="middle">
                      <Form.Item>
                        <Button onClick={handleSubmit} type="primary" disabled={isSubmitting || errors.starting || errors.description}>
                          Save
                        </Button>
                      </Form.Item>
                    </Row>
                    
                  </Form>
                </div>
                
              </div>
            );
          }}
        </Formik>

        <div className="tableData">
          <h1>My Active Auctions</h1>
          <Table rowData={activeAuction} tableData={activeTableTransactions} />
          <br />
          <h1>My Completed Auctions</h1>
          <Table rowData={completedAuction} tableData={completedTableTransactions} />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default connect(mapStateToProps)(AuctionPageT)
