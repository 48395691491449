import React, { useEffect, useMemo } from "react";
import { useDispatch, connect } from "react-redux";
import {  } from "../../../_actions/user_actions";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getStudentAuctions, getStudentUBucks, placeBid } from "../../../_actions/user_actions";
import { Form, Input, Button, Row, Col, message, Statistic } from 'antd';
import Table from '../Table/Table'
import moment from 'moment'
import './Money.css'

const { Countdown } = Statistic;

const formItemLayout = {
  labelCol: {
    xs: { span: 44 },
    sm: { span: 12 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

function AuctionPage(props) {
  const dispatch = useDispatch();
  const userData = props.data.user?.userData ?? ["Loading Data"]
  const auctions = useMemo(() => {return props.data.user?.auctions ?? []}, [props.data.user.auctions])
  const auction = props.data.user?.auction ?? {item: '', currentBid: 999999, winner_id: {_id: 0}}
  const uBucks = props.data.user?.buck ?? 0
  let formikSetter = null

  let auctionsWithTeacher = auctions.map((auction, index) => {
    let tempAuction = {...auction}
    tempAuction.auctioner = auction.owner_id.lastName
    tempAuction.endDate = moment(auction.endDate).format('YYYY-MM-DD HH:mm:ss')
    tempAuction.countdown = <Countdown value={moment(auction.endDate).valueOf()} format="DD:HH:mm:ss" />
    tempAuction._id = auction._id
    if (auction.winner_id) {
      tempAuction.winner = auction.winner_id.firstName + " " + auction.winner_id.lastName
      tempAuction.winner_id = auction.winner_id._id 
    }
    delete tempAuction.owner_id
    return tempAuction
  })

  const [activeAuction, completedAuction] = auctionsWithTeacher.reduce(([p, f], e) => (e.active ? [[...p, e], f] : [p, [...f, e]]), [[], []]);

  useEffect(() => {

    let intervalId = setInterval(() => {
      dispatch(getStudentAuctions())
    }, 5 * 60 * 1000);

    dispatch(getStudentAuctions())
    dispatch(getStudentUBucks())

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 15 * 60 * 1000);

    // Cleanup interval and timeout on component unmount
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [dispatch])

  const outsideSetValueHandler = (values, setFieldValue) => {
    formikSetter = setFieldValue
  }

  const activeTableTransactions = {
    onClick: (e) => {
  
      formikSetter('item', e._id)
      formikSetter('bid', e.bid + 1)
      formikSetter('currentBid', e.bid)
      formikSetter('winner', e.winner_id)
      
    },
    tableHeadings: [
      'bid', 'description', 'auctioner', 'winner', 'endDate', 'countdown'
    ],
  }

  const completedTableTransactions = {
    onClick: (e) => {
      
    },
    tableHeadings: [
      'bid', 'description', 'auctioner', 'winner', 'endDate'
    ],
  }
  
  return (
    <div className="body">
      <div className="innerBody">
        <Formik
          enableReinitialize
          initialValues={{
            item: auction.item,
            ubucks: uBucks.value,
            bid: auction.currentBid + 1,
            currentBid: auction.currentBid,
            winner: auction.winner_id._id
          }}
          validationSchema={Yup.object().shape({
            ubucks: Yup.number().min(0),
            bid: Yup.number().min(0).integer()
          })}

          onSubmit={(values, { setSubmitting }) => {
            let dataToSubmit = {
              item: values.item,
              bid: parseInt(values.bid)
            };
            dispatch(placeBid(dataToSubmit)).then(response => {
              dispatch(getStudentUBucks())
              if (response.payload) {
                message.error(response.payload)
                
              }
              else {
                message.success("Success")
              }
              
              setSubmitting(false);
            })
          }}

        >

          {props => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit
            } = props;

            return (
              <div>
                <div className="form">
                  <h1>Auction</h1>
                  <Form {...formItemLayout} onSubmit={handleSubmit}>
                    {outsideSetValueHandler(values, setFieldValue)}
                    <Row>
                      <Col span={8}>
                        <Form.Item label="UBucks">
                          <Input
                            readOnly
                            id="ubucks"
                            type="int"
                            value={values.ubucks}
                           />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="Max Bid">
                          <Input
                            id="bid"
                            type="int"
                            value={values.bid}
                            onChange={handleChange}
                            onBlur={handleBlur}
                           />
                           {errors.bid && touched.bid && (
                              <div className="input-feedback">{errors.bid}</div>
                            )}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="Current Bid">
                          <Input
                            readOnly
                            id="current"
                            type="int"
                            value={values.currentBid}
                            onChange={handleChange}
                            onBlur={handleBlur}
                           />
                           {values.bid < values.currentBid && (
                              <div className="input-feedback">{errors.item}</div>
                            )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row type="flex" justify="center" align="middle">
                      <Form.Item>
                        <Button onClick={handleSubmit} type="primary" disabled={isSubmitting || errors.item || errors.bid || errors.ubucks || values.winner === userData._id || values.bid > values.ubucks || values.bid <= values.currentBid}>
                          Bid
                        </Button>
                      </Form.Item>
                    </Row>
                    
                  </Form>
                </div>
                
              </div>
            );
          }}
        </Formik>

        <div className="tableData">
          <h1>Active Items</h1>
          <Table rowData={activeAuction} tableData={activeTableTransactions} />
          <br />
          <h1>Won Items</h1>
          <Table rowData={completedAuction} tableData={completedTableTransactions} />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default connect(mapStateToProps)(AuctionPage)
